
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import TechnologistOrderFormGeneralInfo from '@/components/views/NewProjectView/Order/TechnologistOrderFormGeneralInfo.vue';
import TechnologistOrderFormOrders from '@/components/views/NewProjectView/Order/TechnologistOrderFormOrders.vue';

@Component({
    name: 'TechnologistOrderForm',
    components: {
        TechnologistOrderFormGeneralInfo,
        TechnologistOrderFormOrders,
    },
})
export default class TechnologistOrderForm extends Vue {
    @Prop({ required: true }) private project!: any;

    private visible: boolean = false;
    private numberOfOrders: string = '0';

    private async openPopup() {
        this.visible = true;
    }

    private closePopup() {
        this.visible = false;
    }

    private async submitForm() {
        try {
            const generalInfoForm = this.$refs.generalInfoForm as any;
            console.log(generalInfoForm);
            const formValues = await generalInfoForm.validateForm();
            console.log('Form is valid:', formValues);
            // Proceed with form submission logic
        } catch (err) {
            console.error('Validation failed:', err);
        }
    }

    private ordersNumberChange(value: string) {
        this.numberOfOrders = value;
    }

    private mounted() {
        EventBus.$on(EventBusEvents.showTechnologistOrderForm, this.openPopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.showTechnologistOrderForm, this.openPopup);
    }
}
