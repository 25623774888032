
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { TechnologistFormOrder } from '@/interfaces/components/projectNew/TechnologistFormOrder';
import ProductSystem from '@/models/ProductSystem';
import { ProductSystemRepository } from '@/repositories/ProductSystemRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'TechnologistOrderFormOrders',
    components: {},
})
export default class TechnologistOrderFormOrders extends Vue {
    @Prop({ required: true }) private numberOfOrders!: string;

    private orders: TechnologistFormOrder[] = [];
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get systems() {
        return ProductSystemRepository.getAll();
    }

    private addOrder() {
        const order = {
            system: '',
            quantity: 0,
        };

        this.orders.push(order);
        this.$emit('ordersChanged', this.orders.length);
    }

    private deleteOrder(index: number) {
        this.orders.splice(index, 1);
        this.$emit('ordersChanged', this.orders.length);
    }

    private onNumberOfOrdersChange() {
        if (!this.numberOfOrders) {
            return;
        }

        this.orders = new Array(parseInt(this.numberOfOrders)).fill({
            system: '',
            quantity: 0,
        });
    }

    private async mounted() {
        this.onNumberOfOrdersChange();

        try {
            this.loadingOverlay.start();
            await ProductSystem.getAll();
        } catch (error) {
            console.error(error);
        } finally {
            this.loadingOverlay.stop();
        }
    }

    @Watch('numberOfOrders', { immediate: true })
    private onNumberOfOrdersChangeImmediate() {
        if (!this.numberOfOrders) {
            return;
        }

        if (this.orders.length === parseInt(this.numberOfOrders)) {
            return;
        }

        if (this.orders.length < parseInt(this.numberOfOrders)) {
            const difference = parseInt(this.numberOfOrders) - this.orders.length;

            for (let i = 0; i < difference; i++) {
                this.orders.push({
                    system: '',
                    quantity: 0,
                });
            }
        }
    }
}
