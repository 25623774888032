
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { technologistOrderFormConfiguration } from '@/helpers/OrderHelper';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { TechnologistFormField } from '@/interfaces/components/projectNew/TechnologistFormField';

@Component({
    name: 'TechnologistOrderFormGeneralInfo',
    components: {},
})
export default class TechnologistOrderFormGeneralInfo extends Vue {
    @Prop({ required: true }) private project!: any;
    @Prop({ required: true }) private numberOfOrders!: number;

    private errors: { [key: string]: string } = {};

    // Create formData based on form fields
    private formData: { [key: string]: any } = {};

    private get orderFormConfiguration() {
        return technologistOrderFormConfiguration;
    }

    private get regularFormFields(): TechnologistFormField[] {
        return this.orderFormConfiguration.formFields.filter((field) => field.type !== 'date');
    }

    private get dateFormFields(): TechnologistFormField[] {
        return this.orderFormConfiguration.formFields.filter((field) => field.type === 'date');
    }

    private get locale() {
        return getDatepickerLocale();
    }

    private getLabel(field: TechnologistFormField) {
        // Return label with * if field is required
        return field.required ? `${this.$t(field.label)} *` : `${this.$t(field.label)}`;
    }

    private handleFileChange(field: TechnologistFormField, { fileList }: any) {
        this.formData[field.name] = fileList;
        this.onFormChange(field); // Clear the error if a file is selected
    }

    private customRequest({ file, onSuccess, onError }: any) {
        // Simulate successful file upload
        setTimeout(() => {
            onSuccess(file);
        }, 1000);
    }

    private onFormChange(field: TechnologistFormField) {
        if (field.name === 'ordersNumber') {
            this.$emit('ordersNumberChange', this.formData[field.name]);
        }

        if (!this.errors[field.name]) {
            return;
        }

        this.validateField(field);
    }

    private setInitialFormValues() {
        this.formData = technologistOrderFormConfiguration.formFields.reduce((acc: { [key: string]: any }, field) => {
            if (field.type === 'number') {
                acc[field.name] = 0; // Default value for numbers
            } else if (field.type === 'textarea' || field.type === 'text') {
                acc[field.name] = ''; // Default value for text or textarea
            } else if (field.type === 'dropdown') {
                acc[field.name] = null; // Default for dropdown
            } else if (field.type === 'attachment') {
                acc[field.name] = []; // Default empty array for attachments
            } else if (field.type === 'date') {
                acc[field.name] = null; // Default null for dates
            }
            return acc;
        }, {});
    }

    private validateField(field: TechnologistFormField) {
        if (!field.required) {
            delete this.errors[field.name];
            return;
        }

        const value = this.formData[field.name];
        let isValid = true;

        if (field.type === 'number' && value === 0) {
            isValid = false;
        } else if ((field.type === 'text' || field.type === 'textarea') && !value?.trim()) {
            isValid = false;
        } else if ((field.type === 'dropdown' || field.type === 'date') && (value === null || value === undefined)) {
            isValid = false;
        } else if (field.type === 'attachment' && value.length === 0) {
            isValid = false;
        }

        if (!isValid) {
            this.$set(this.errors, field.name, `${field.label} ${this.$t('is required')}`);
        } else {
            this.$delete(this.errors, field.name);
        }
    }

    private validateForm(): Promise<{ [key: string]: any }> {
        return new Promise((resolve, reject) => {
            this.orderFormConfiguration.formFields.forEach((field) => {
                if (field.required) this.validateField(field);
            });

            if (Object.keys(this.errors).length === 0) {
                resolve(this.formData);
            } else {
                this.$notification.error({
                    message: 'Validation Error',
                    description: Object.values(this.errors).join(', '),
                });
                reject({ ...this.errors });
            }
        });
    }

    private mounted() {
        this.setInitialFormValues();

        if (!this.project) {
            return;
        }

        // Set predefined values from project
        this.formData.client = this.project?.client.name ?? '';
        this.formData.commercialist = this.project?.assignedUser?.name ?? '';
        this.formData.projectConnection = this.project?.connection ?? '';
    }

    @Watch('numberOfOrders', { immediate: false })
    private onNumberOfOrdersChange() {
        this.formData.ordersNumber = this.numberOfOrders;
    }
}
